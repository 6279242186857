import * as React from "react"
import styled from 'styled-components/macro'
import Logos from './Logos';
import Colors from './Colors';
import Fonts from './Fonts';

const BrandPageWrapper = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  padding-top: 75px;
`

const Navbar = styled.div`
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: #f9f9f9;
`

const NavLink = styled.a`
  text-decoration: none;
  color: black;
  display: inline-block;
  margin: 18px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
`

const BrandPage = () => {
  return (
    <BrandPageWrapper>
      <Navbar>
        <NavLink href='#logos'>
          Logos
        </NavLink>
        <NavLink href='#colors'>
          Colors
        </NavLink>
        <NavLink href='#fonts'>
          Fonts
        </NavLink>
      </Navbar>
      <Logos/>
      <Colors/>
      <Fonts/>
    </BrandPageWrapper>
  )
}

export default BrandPage
